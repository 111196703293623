.alert-block.callout{
    border: none;   
}

.alert-container{
    @include breakpoint(large){
        margin-bottom: rem-calc(-50);
        width: 100%;
        max-width: rem-calc(725);
        margin-left: auto;
        margin-right: auto;
        transition: all ease .4s;
    }
    .alert-block {
        display: block;
        background-color: #CFF2EA;
        height: rem-calc(40);
        margin-bottom: rem-calc(7);

        &__message {
            color: #0E6251;
            font-size: rem-calc(14);
            font-weight: 700;
            line-height: rem-calc(8.5);
            font-size: rem-calc(12.5);

            @include breakpoint(medium) {
                font-size: rem-calc(14);
            }

        }
    }

}
