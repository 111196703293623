
.slider {
    width: 100%;
    height: rem-calc(24);    
    -webkit-transition: .2s;
    transition: opacity .2s;
    -webkit-appearance: none;
    appearance: none;
    background: #E9ECEF;
    outline: none;
    border-radius: rem-calc(12);
    overflow: hidden;
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: rem-calc(24);
        height: rem-calc(24);
        border-radius: 50%;
        background: $white;
        cursor: pointer;
        box-shadow: -407px 0 0 400px #1ABC9C;
        border: 2px solid #1ABC9C;
    }
    &:-moz-range-thumb {
        width: rem-calc(24);
        height: rem-calc(24);
        cursor: pointer;
        border-radius: 50%;
        background: white;
        -webkit-appearance: none;
        appearance: none;  
        border-radius: 50%;
        background: $white;   
        box-shadow: -407px 0 0 400px #1ABC9C;
        border: 2px solid #1ABC9C;
    }
}