.progress-indicator {
    list-style: none;       
    display: table;
    table-layout: fixed;
    font-family: 'Roboto', sans-serif;
    margin: auto;     
    width: 100%;    
    margin-top: rem-calc(65);
    margin-bottom: rem-calc(100);
    max-width: rem-calc(730);
    @include breakpoint(medium){
        margin-top: rem-calc(70);
        margin-bottom: rem-calc(100);       
    }
    >li {
        position: relative;
        display: table-cell;
        text-align: center;
        font-size: rem-calc(14);        
        color: #323A46;           
        span {
            position: absolute;
            color: #6C757D;            
            transform: translateX(-50%);        
            font-weight: normal;                      
            margin-top: rem-calc(10);
            font-size: rem-calc(10.5);
            line-height: rem-calc(14);          
            @include breakpoint(medium){
                font-size: rem-calc(13);
                line-height: rem-calc(15);
            }
                       
        }      
      
        &::before {
            content: attr(data-step);
            display: block;
            margin: 0 auto;
            background: #929292;            
            color: $white;         
            text-align: center;
            margin-bottom: 0.25em;
            line-height: rem-calc(24);            
            border-radius: 100%;
            position: relative;
            z-index: 1000;
            width: rem-calc(24);
            height: rem-calc(24);            
            @include breakpoint(medium){
                width: rem-calc(24);
                height: rem-calc(24);
            }
        }

        &::after {
            content: '';
            position: absolute;
            display: block;
            background: #c4c4c4;
            width: 70%;
            height: 1px;
            top: 50%; // height of bar
            transform: translateY(-100%);
            left: 64.75%;
            margin-left: 1.5em\9;
            z-index: 0;             
            
        }

        &:last-child:after {
            display: none;
        }
        
        &.is-complete {                       

            &::before,
            &::after {
                color: $white;
                background: $green;
            }        
         
            span {
                color: $green;
            }
        }

        &.is-current {
            color: #008FFB;           

            &::before {
                color: $white;
                background: #008FFB;
                
            }

            span {
                color: #008FFB;
                
            }
        }
    }
}




.progress-indicator>li.is-complete::after {
    color: $white;
    background: #c4c4c4;
}
.progress-indicator>li.is-complete span {
    color: #6C757D;
}
.progress-indicator>li.is-complete-active span,
.progress-indicator>li.is-current span {
    color: #323A46;
    font-weight: 500;
}
.progress-indicator>li.is-complete::before {
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     content: "\f00c";    
}


@media screen and(max-width: 375px){
    .progress-indicator{
        position: relative;
        left: rem-calc(10);
        padding-left: 2.5px;
        padding-right: 2.5px;
    }
    .progress-indicator li:first-child{
        position: relative;
        left: rem-calc(-10);       
      
    }   
    .progress-indicator li:first-child:after {   
        margin-left: rem-calc(5);

    }
}