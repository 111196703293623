  @mixin transition {
      transition: all ease .4s;
  }
 
  @mixin btn {
     background-color: $primary;
     color: $white;
     font-weight: 400;    
     border-radius: 4px;
     padding: rem-calc(10 30);    
     line-height: rem-calc(16);
     @include transition;
     @include breakpoint(small only) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
     }
     @include breakpoint(medium){
         font-size: rem-calc(16);
         line-height: rem-calc(22);
     }   
     &:hover{
        background-color: #097ff5;
     }
  }
  @mixin btn-offset {
     background-color: transparent;
     color: $primary;
     border: 2px solid $primary;
     font-weight: 400;    
     border-radius: 4px;
     padding: rem-calc(10 30);
   //   font-size: rem-calc(13);
     line-height: rem-calc(16);
     @include transition;
     @include breakpoint(small only){
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
     }    
     &:hover{
         background-color: $primary;
         color: $white;        
     }  
     @include breakpoint(medium){
         font-size: rem-calc(16);
         line-height: rem-calc(28.60);
     }
    
     
  }


  @mixin title-wizard{
     color: #35383F;
     font-weight: 400;
     font-size: rem-calc(18);
     @include breakpoint(medium){
        font-size: rem-calc(24);
        line-height: rem-calc(32.75);
     }  
     
  }
  @mixin title{
     color: #35383F;     
     font-weight: 700;
     font-size: rem-calc(18);
     @include breakpoint(medium){
        font-size: rem-calc(30);
        line-height: rem-calc(40.92);
     }  
     
  }
   @mixin content {
      font-weight: 400;
      font-size: rem-calc(14);
      line-height: rem-calc(19);
      color: $title;
      margin: rem-calc(30 0 40 0);

   }

  @mixin center{
     position: relative;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
  }