.modal{
    background: rgba(0, 0, 0, 0.5);
}
.modal-campaign{    
    &__widget{
        position: relative;      
        top: rem-calc(80);
        margin: auto;
        width: 100%;
        max-width: rem-calc(720);
        background-color: $white;
        border-radius: 4px;
        padding: rem-calc(20);
        @include breakpoint(medium){
            padding: rem-calc(50);
            top: rem-calc(120);
        }
        h2{
            font-weight: 700;
            margin-bottom: rem-calc(20);
            color: #35383F;            
            @include breakpoint(medium){
                width: 90%;
                margin: auto;
                margin-bottom: rem-calc(54);
                text-align: center;
                font-size: rem-calc(24);
                line-height: rem-calc(33);
                margin-bottom: rem-calc(40);
            }
        }
        p{
            font-size: rem-calc(13);
            line-height: rem-calc(19);
            font-weight: 400;
            color: #323A46;
            margin-bottom: rem-calc(25);
            text-align: center;
            @include breakpoint(medium){
                font-size: rem-calc(13);
                margin-bottom: rem-calc(46);
            }
        }
        &__buttons {    
            @include breakpoint(medium down){
                display: flex;
                flex-direction: column;
            }      
            @include breakpoint(medium){
               margin-left: rem-calc(15);
               
            }
            button{ 
                 @include btn-offset;
                 font-size: rem-calc(13);                
                 @include breakpoint(medium down) {
                     font-size: rem-calc(14);
                     display: block;
                     width: 100%;
                     margin-bottom: rem-calc(15);
                     padding: rem-calc(13 20);
                 }                                        
                @include breakpoint(medium) {
                     font-size: rem-calc(14);
                 }                                        
                               
            }
            a{
                @include btn;
                text-align: center;             
                position: relative; 
                padding: rem-calc(13 20);
                @include breakpoint(large){                     
                     top: rem-calc(5);
                     left: rem-calc(20);
                }
                
            }
        }
    }
}