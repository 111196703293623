.control {
    display: block;
    position: relative;
    padding-left: rem-calc(30);
    margin-bottom: rem-calc(15);
    cursor: pointer;    
}
.control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: rem-calc(16);
    width:  rem-calc(16);
    background: #FFFFFF;
    border: 1px solid #abacac;
    border-radius: 4px;
}

.control input:checked~.control__indicator {
    background: #fff;
}
.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}

.control input:checked~.control__indicator:after {
    display: block;
}

.control--checkbox .control__indicator:after {
    left: 5.5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid #6C757D;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}