.widget{
    background-color: $white;    
    margin: auto;   
    width: 100%;
    padding: rem-calc(60 40);
    margin-bottom: 0;
    @include breakpoint(medium){
         padding: rem-calc(60 100);        
           
    }
    @include breakpoint(large){
        max-width: rem-calc(730);
    }
    &--merchant{
        padding-top: rem-calc(30);
        @include breakpoint(small only){
            margin-bottom: rem-calc(-50);
        }
       
    }  
    &--google{
        @include breakpoint(medium) {
            padding: rem-calc(40 80 10 80);            
               
        }
    } 
   
    &__title{
        @include title-wizard;  
        margin-bottom: rem-calc(70); 
        @include breakpoint(small only){
            text-align: center;
            margin-bottom: rem-calc(20);
        }                
        &--google{
            text-align: center;
            margin-bottom: rem-calc(30);
        } 
        &--bold{
            @include title;
            margin-bottom: rem-calc(24);            
        }
        &--verify{
            @include title;
            text-align: center;
            margin-bottom: rem-calc(20);
        } 
            
        
    }
    &__text{
        @include content;
        margin-bottom: rem-calc(44);
        text-align: center;
    }
    &__success{
       @include center;
       
    }

    &__content {
        @include content;
        font-weight: 400;   
        text-align: center;  
        &--google{
            margin: rem-calc(45 0);
        }   

    } 
    &__code-warning{
        color: $text-grey;
        text-align: center;
        font-size: rem-calc(14);
        line-height: rem-calc(19);
    }  
    &__code{
        display: block;
        font-weight: 700;
        margin-bottom: rem-calc(30);
    }
    &__code-group{
       @include breakpoint(large){
            width: 75%;
            margin: auto;
       }
        p{
            @extend .widget__code-warning;
            text-align: left;
            font-weight: 600;
        }
        .input-group{
            @include breakpoint(medium down){
                display: flex;
                flex-direction: column;               
                
            }
            input{
                font-weight: 400;
                margin-bottom: 0;
                border: 1px solid #CED4DA;                
                border-radius: 4px;                
                margin-right: 2px;
                padding: rem-calc(20 15);
                @include breakpoint(medium down) {
                    margin-bottom: rem-calc(15);
                }
                @include breakpoint(large){
                    width: 62%;
                }
            }
            button{
                @include btn;
                margin-left: 2px;
                cursor: pointer;
                padding: rem-calc(10);
                font-size: rem-calc(13)!important;
                line-height: rem-calc(18);                
                pointer-events: all;
            }
        }
         &__buttons {
             display: flex;
             flex-direction: column;
             text-align: center;
             @include breakpoint(medium) {
                 flex-direction: row;
             }            
             button,
             a {
                 margin: rem-calc(5);
                 font-size: rem-calc(13);                

                 @include breakpoint(medium) {
                     font-size: rem-calc(16);
                     line-height: rem-calc(23);
                 }

                 &:first-child {
                     @include btn-offset;
                     padding: rem-calc(7.5 30);
                     font-size: rem-calc(16)!important;
                     @include breakpoint(small only) {
                        width: 100%;
                        padding: rem-calc(10 30);
                    }
                 }
                 &:last-child {
                    background-color: #F4F5F7;
                    pointer-events: none;
                 }
               
             }     
                      
                      
         }
        #btn-phone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: rem-calc(45);
            width: 100%;
            color: $white;        
            border-radius: 4px;           
         }
    }


}
.disable-btn {
    background-color: #F4F5F7;
    cursor: not-allowed;
    pointer-events: none;
}

.enable-btn {
    background-color: #008FFB!important;
    pointer-events: all!important;
    cursor: pointer!important;
    

}

