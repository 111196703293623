.resume {
    @extend .widget;
    margin-bottom: rem-calc(50);

    @include breakpoint(small only) {
        padding: rem-calc(20);
    }

    &__item {
        display: flex;
        margin-bottom: rem-calc(25);

        &__user {
            @extend .widget__connect__user;
            
        }

    }

    &__item:nth-child(2) {
        margin-bottom: 0;       
        p {
            margin-left: rem-calc(7.5);

        }
    }

    &__item:nth-child(4) {
        @extend .resume__item:nth-child(2);
        margin-bottom: rem-calc(17);
       @include breakpoint(large) {
         margin-top: rem-calc(15);
         margin-bottom: rem-calc(12.5);
       }
        @include breakpoint(small only) {
            margin: rem-calc(10 0);
        }
    }

    &__confirm {
        @include breakpoint(medium) {
            position: relative;
            left: rem-calc(46);
        }

        p {
            font-weight: 700;
            font-size: rem-calc(14);
            line-height: rem-calc(19);
            text-align: left;
            color: $text-grey;
            margin-top: rem-calc(10);

            @include breakpoint(medium) {
                width: 65%;
            }
        }

        label {
            font-weight: 400;
            font-size: 12px;
            line-height: rem-calc(16);
            text-align: left;
            color: $text-grey;
            margin-left: rem-calc(5);


        }

        .checkbox-group {
            display: flex;
            height: 100%;
            align-items: center;
            input{
                border: 1px solid #abacac;
            }
            label {
                font-weight: 400;
                font-size: 12px;
                line-height: rem-calc(16.35);
                text-align: left;
                color: $text-grey;
                margin-left: rem-calc(5);

                @include breakpoint(medium) {
                    font-size: 14px;
                }

            }

            span {
                position: relative;
                top: rem-calc(-10);
                left: rem-calc(5);
                cursor: pointer;
            }
        }

    }


    &__content {
        @include content;
        font-weight: 700;
        margin-bottom: rem-calc(20);
        color: $text-grey;

        @include breakpoint(medium) {
            position: relative;
            left: rem-calc(46);
        }
    }

    &__buttons {
        margin-top: rem-calc(50);
        text-align: center;

        button,
        a {
            @include btn;

        }

        .disable-btn {
            background-color: #F4F5F7;
            cursor: not-allowed;
            pointer-events: none;
        }
    }
}


.resume__confirm:nth-child(6),
.resume__confirm:nth-child(7) {
    display: flex;
    height: 100%;
    align-items: center;

    @include breakpoint(small only) {
        display: flex;
        height: 100%;
        align-items: center;
        margin-bottom: rem-calc(10);
    }

    label {
        @include breakpoint(small only) {            
            margin-top: 1px;
        }
    }

    span {
        position: relative;
        top: rem-calc(-7.5);
        left: rem-calc(7);
        cursor: pointer;

        img {
            @include breakpoint(small only) {
                width: rem-calc(18) !important;
                height: rem-calc(18) !important;
                position: relative;
                top: rem-calc(-7);
                right: rem-calc(4);
            }
        }
    }
}
