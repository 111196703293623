input[type=radio] {
    visibility: hidden;
}
input[type=radio]:checked+label {
    &:after{
        opacity: 1;
    }
    &:before{
        background: $primary;
    }
}


label.radio {
    cursor: pointer;
    text-indent: rem-calc(30);
    overflow: visible;
    display: inline-block;
    position: relative;    
    border: 1px solid transparent;
    font-weight: 600;    
    @include breakpoint(medium){        
        font-size: rem-calc(14);
        line-height: rem-calc(19);
    }
  
    &:before{
        background: $white;
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: rem-calc(18);
        height: rem-calc(18);
        border-radius: 100%;
        background: $white;
        border: 1px solid #CED4DA;
    }
    &:after {
        opacity: 0;
        content: '';
        position: absolute;
        width: 8px;
        height: 5px;
        background: transparent;
        top: 7.25px;
        left: 5px;
        border: 2px solid $white;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transition: all ease .3s;
    }

}



