 .widget{
    &__connect {
        margin: auto;
        display: flex;        
        height: 100%;       
        flex-direction: row;
        justify-content: center;
        align-items: center;
        &--merchant {
            float: left;
        }
        &--google {
            @include breakpoint(small only) {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                a {
                    margin-top: rem-calc(20);
                }
            }
        }
        &--success {
            margin-bottom: rem-calc(88);
          
        }

        &__img {          

            img {
                margin-right: rem-calc(8);
                width: rem-calc(30);
                height: rem-calc(30);
            }
        }

        &__buttons {
            button,
            a {
                @include btn;
                font-weight: 400;
                font-size: rem-calc(16);
                line-height: rem-calc(22);

                @include breakpoint(small only) {
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: rem-calc(12.75);                   
                }

                @include breakpoint(medium) {
                    margin-left: rem-calc(8);

                }
            }
            &--connect{
                a{
                    padding: rem-calc(12 40);
                }
            }
        }

        &__user {
            margin-left: rem-calc(10);

            p {
                margin-bottom: 0;
                color: $text-grey;
                font-weight: 700;
                font-size: rem-calc(14);
                line-height: rem-calc(19);

                span {
                    margin: rem-calc(0 2);
                    cursor: pointer;
                }
            }

            p:last-child {
                color: #323A46;

            }

        }
   
       
    }
   
 }