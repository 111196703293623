.account{
    @extend .widget;   
    @include breakpoint(medium) {
        padding: rem-calc(0 100 50 100);       
    }
    &__block{
        display: flex;
        flex-direction: row;
        margin-bottom: rem-calc(20);       
        &__img{
            img{
                width: rem-calc(30);
                height: rem-calc(30);
            }
        }
        &__text{           
            margin-left: rem-calc(17);           
            p{
                 margin-bottom: 0;
                 color: #323A46;                 
                 font-weight: 700;
                 font-size: rem-calc(14);
                 line-height: rem-calc(19);
            }
        }
         &--store {
             @include breakpoint(small only){
                 margin-left: rem-calc(-17);
             }
            @include breakpoint(medium){
                left: rem-calc(30);
                position: relative;
            }          
            p:first-child{
                color: $text-grey;
            } 
            small {
                color: $text-grey;

                span {
                    margin-left: rem-calc(4);
                    cursor: pointer;
                }
            }
            
         }       
    }
    
    &__form{
        @include breakpoint(medium){
            left: rem-calc(45);
            position: relative;
        }      
        &__item{
            margin-bottom: rem-calc(7.5);
            label{
                color: $text-grey;
                font-weight: 700;
                font-size: rem-calc(14);
                line-height: rem-calc(19);
                margin-bottom: rem-calc(5);
            }
            input{
               @extend label;
               font-weight: 400;
               margin-bottom: 0;
               border: 1px solid $text-grey;               
               border-radius: 4px;   
               &::placeholder{
                   color: #bbbbbb;
               }
            }
            input[type=text]{
                max-width: rem-calc(109);
            }          
            input[type=text]:last-child {
                max-width: rem-calc(165);               
            }
          
            small{
                color: $text-grey;
                span{
                    margin-left: rem-calc(4);
                    cursor: pointer;
                }
            }
            &:nth-child(2){
                input[type=text] {
                     max-width: rem-calc(260);
                }
            }
            &:nth-child(3){
                 margin-top: rem-calc(15);
                input[type=text] {
                     max-width: rem-calc(180);
                }                
            }

            &--checkbox{
              margin: rem-calc(10 0 45 0);
               color: $text-grey;
               p{
                   font-weight: 700;
                   margin-bottom: 0;
               }
               .radio-group{                   
                   position: relative;
                   display: flex;                   
                   @include breakpoint(medium){
                       left: rem-calc(-20);
                   } 
                   label{
                       line-height: rem-calc(24);
                   }               
               }
              .radio-group__item:last-child{                 
                   @include breakpoint(medium) {
                        margin-left: rem-calc(-24);
                   }
              }
                input[type=radio] {
                    display: inline-block;
                    margin: 0;
                }
                label{
                    margin-left: rem-calc(-5);
                    font-weight: 600;                    
                }

            }
            &--confirm{
                display: flex;
                margin-bottom: rem-calc(35);
                label{
                    margin-top: rem-calc(-2.5);
                    font-weight: 400;
                    font-size: rem-calc(12);
                    line-height: rem-calc(16);
                    color: $primary;
                    span{
                        color: $text-grey;
                        img{
                            margin-left: rem-calc(4);
                            cursor: pointer;
                        }
                    }
                }                
            }

            &--buttons{
                display: flex;
                flex-direction: column;
                text-align: center;
                @include breakpoint(medium){
                    flex-direction: row;
                }
                @include breakpoint(large) {
                    margin-left: rem-calc(-30);
                } 
                button,               
                a{                    
                    margin: rem-calc(5);
                    font-size: rem-calc(13);
                    @include breakpoint(medium){
                        font-size: rem-calc(16);
                        line-height: rem-calc(23);
                    }
                    &:first-child{
                        @include btn-offset;
                        padding: rem-calc(7.5 30);
                        font-size: rem-calc(16)!important;
                    }
                    &:last-child{
                        @include btn;                       
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: rem-calc(45);                      
                        
                    }
                }
                a.disable-btn {                  
                    background-color: #F4F5F7;                    
                    cursor: not-allowed;
                    pointer-events: none;
                }
            }
        }
        &__confirm {
            @extend .widget-products__confirm;
            margin-bottom: rem-calc(12.5) !important;

            label {
                color: $black;               
                span {
                    color: $primary;  
                                  
                }
                label[for="merchant-confirm"] {
                   position: relative;
                   top: rem-calc(4);
                }
            }
            img {
                margin-left: rem-calc(7.5);
                 margin-top: rem-calc(-10);
            }
        }

    }
   
}

@media screen and (max-width: 333px){
    .account__form__item--checkbox .radio-group label {
        font-size: rem-calc(11.35);

    }
}
