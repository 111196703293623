header {
	grid-area: header;
	background: $title;
	display: flex;
	flex-direction: row;
	justify-content: space-between; 
	height: rem-calc(82); 
	z-index: 9999;
	position: relative;
	.logo-container {	 
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  margin-left: rem-calc(40);	
  
	  .icon-bar {
		color: $white;		

	  }
  
	  .logo {
		margin-left: rem-calc(40);
	
	  }
	}  

  
	.ads {
		position: relative;
		top: rem-calc(15);						
		img{
			width: rem-calc(200);
			height: rem-calc(50);
			vertical-align: middle;
		}
	}
	nav{
		display: flex;
		flex-direction: row;
		height: 100%;
		justify-content: center;
		align-items: center;
		.search-bar {
			@include breakpoint(large){
				position: relative;
				top: rem-calc(7.5);
				left: rem-calc(-20);				
			}
			input {			
				outline: none;
				background-color: #4D555D;
				border: none;
				padding: rem-calc(16);
				padding-left: rem-calc(20);
				border-radius: rem-calc(25);
				&:focus{
					outline: none;
					background-color: #4D555D;
					color: $white;
				}
			
		  }  
		 
		}
	  
		ul {	
		  display: flex;
		  align-items: center;
	  
		  li {
			list-style: none;
	  
			a {
			  background: none;	
			  font-size: rem-calc(16); 		 
			  color: $white;
			  
			 
			}
			.user-account{
				display: flex;
				flex-direction: row;
				height: 100%;
				align-items: center;
				justify-content: center;
				&:hover{
					color: $white;
				}
				select{
					background-color: transparent;
					border: none;
					width: rem-calc(20);
					color:  $white;
					margin-left: rem-calc(-5);
					outline-style: none;
					font-size: rem-calc(16);
					font-weight: 400;
					&:focus{
						outline: none ;
						box-shadow: none;
					}
				}
			}
		  }
		}

	}
  }
