main {
    width: 100%;
    overflow: hidden;
    @include breakpoint(large){
        padding-bottom: rem-calc(150);
    }
}

body{
    overflow-x: hidden;
    font-family: "Nunito Sans", sans-serif;
        
}
button{
    cursor: pointer;
}
.disable-btn {
    background-color: #F4F5F7;
    cursor: not-allowed;
    pointer-events: none;
}

blockquote{
    border: none;
    
}