.smart-shopping{
    @extend .widget;
    padding-top: rem-calc(30);
    padding: rem-calc(30 50);
    margin-bottom: rem-calc(50);
    &--campaign{
        @include breakpoint(small only){
            padding: rem-calc(10);
        }
       @include breakpoint(medium){
            padding: rem-calc(60 100);
       }
    }
    &__title{
        color: #35383F;        
        font-weight: 700;
        font-size: rem-calc(24);
        line-height: rem-calc(33); 
         margin-bottom: rem-calc(20);
         text-align: center;        
    }
    &__img{
        @include center;
          margin: rem-calc(14 0);
        &--campaign{
            margin-bottom: rem-calc(-20);
        }  
    }
    &__text{
        @include content;
        text-align: center;
        color: $black;        
        @include breakpoint(medium){
            width: 55%;
            text-align: center;
            margin: auto;
            margin-bottom: rem-calc(25);
            
        }
        &--campaign{
            @include breakpoint(medium){
                 width: 65%;
            }
        }
    }
    &__iframe{
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; 
        margin-bottom: rem-calc(55);       
        iframe{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__buttons{
        display: flex;
        flex-direction: column;
        @include breakpoint(large){
            flex-direction: row;
            justify-content: space-evenly;
            
        }
        button{
            @include btn-offset;             
              margin: rem-calc(10);
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: rem-calc(50);
        }
        a{
            @include btn;          
            flex: 1 1 47%;          
            margin: rem-calc(10);
            text-align: center; 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: rem-calc(50);
            @include breakpoint(large){
                 padding: rem-calc(7.5 5);
            }           
                      
        }
        a:first-child {
            @include btn-offset;
            flex: 1 1 47%;
            padding: rem-calc(7.5 5);
            margin: rem-calc(10);
            text-align: center;

        }
       
    }

}