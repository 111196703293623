.swiper-container {
    @include breakpoint(large) {
        left: rem-calc(50);
    }

    @include breakpoint(small only) {
        margin-top: rem-calc(50);
    }

    .swiper-wrapper {
        text-align: center;

        .swiper-slide {
            @include breakpoint(medium down) {
                text-align: center;
            }

            .thumb {                
                @include breakpoint(medium only) {
                    position: relative;
                    left: 25%;

                }
                img {
                    height: rem-calc(106);
                    width: 100%;
                    max-width: rem-calc(106);
                    border-radius: 100%;
                    border: 8px solid #CDDAF2;
                    object-fit: cover;
                    vertical-align: middle;
                    @include breakpoint(medium only) {
                        height: rem-calc(90);
                        width: rem-calc(90);                        
                        
                    }

                }
            }

            .testimonial {
                @include breakpoint(medium down) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    text-align: center;
                    padding: rem-calc(20);
                    position: relative;
                    left: 0;
                }

                @include breakpoint(small only) {
                    left: rem-calc(-20px);
                }

                @include breakpoint(large xlarge) {
                    position: relative;
                    left: rem-calc(20);
                }

                &__name {
                    font-size: rem-calc(18);
                    font-weight: 700;
                    line-height: rem-calc(24.5);
                    color: $black;

                    @include breakpoint(large) {
                        text-align: left;
                    }

                }

                &__content {
                    color: #6C757D;
                    font-size: rem-calc(14);
                    font-weight: 400;
                    line-height: rem-calc(19);
                    padding: rem-calc(20);
                    width: 80%;
                    margin-bottom: rem-calc(-40);

                    @include breakpoint(medium only) {
                        margin-bottom: rem-calc(-20);   
                        width: 90%;
                    }

                    @include breakpoint(large) {
                        width: 65%;
                        margin-left: rem-calc(-25);
                        margin-bottom: rem-calc(-10);
                        text-align: left;
                    }

                    span {
                        display: block;
                        margin: rem-calc(16 0);
                    }

                }

                &__area {
                    font-size: rem-calc(16);
                    font-weight: 400;
                    line-height: rem-calc(22);
                    color: $black;
                    font-style: italic;

                    @include breakpoint(large) {
                        text-align: left;
                        margin-bottom: rem-calc(-15);
                    }
                }

                &__stars { 
                    margin-top: rem-calc(15);  
                    margin-bottom: rem-calc(25);
                    @include breakpoint(small only) {                       
                        margin-left: rem-calc(-5); 
                    }               
                                  
                    @include breakpoint(large) {
                        position: relative;
                        left: rem-calc(40);
                        top: rem-calc(-10);
                        transform: translate(-50%, -50%);
                        
                    }

                    &__item {
                        list-style: none;
                        display: inline-block;

                    }
                }
            }

        }
    }

    .swiper-pagination {
        position: relative;
        top: rem-calc(-40);
        left: 50%;
        transform: translate(-50%, -50%);  

        @include breakpoint(medium only) {
            left: 45%;
            top: rem-calc(-42);
            position: relative;
            top: rem-calc(-40);
        }
        @include breakpoint(large) {
            position: relative;
            left: 39.5%;
        }

        span {
            margin: 25px 2.5px 0 2.5px;

            @include breakpoint(large) {
                width: rem-calc(10);
                height: rem-calc(10);
            }

        }

    }
}
