.presentation{     
    margin: rem-calc(70 0);
    @include breakpoint(large){
        margin: rem-calc(140 0 0  0);
        margin-bottom: rem-calc(-100);
    }
    .grid-container{        
        position: relative;
        .show-case{
            &__info {
                &__title {
                    font-weight: 700;
                    color: #323A46;

                    @include breakpoint(medium) {
                        font-size: rem-calc(24);
                        line-height: rem-calc(33);
                    }

                    @include breakpoint(large) {
                        width: 75%;
                    }
                }

                &__paragraph {
                    @extend .show-case__info__title;                   
                    font-weight: 400;
                    margin-top: rem-calc(20);
                    @include breakpoint(medium only) {
                        margin-bottom: rem-calc(50);
                    }
                    @include breakpoint(medium) {
                        font-size: rem-calc(24);
                        line-height: rem-calc(33);
                    }
                    @include breakpoint(large) {
                         width: 65%;

                     }

                }

            }
            &__img{
                  position: relative;
                  z-index: 1;
                img{
                    vertical-align: middle;
                }
            }
        }
         .google-products {            
            z-index: -1;
            position: absolute;
            top: 58%;    
            display: none;
            @include breakpoint(medium) {
                top: rem-calc(270);               
             }
             @include breakpoint(large) {
                top: rem-calc(250);
                left: 0;
                display: block;                
             }
             &__img {
                 padding: rem-calc(10);
                 img {
                     width: 100%;                  
                
                 }
             }
         }

        .features {
            position: relative;
            top: rem-calc(50);

            @include breakpoint(large) {
                top: rem-calc(-100);
            }

            &__text {
                p {
                    color: $title;  
                    font-size: rem-calc(14);
                    font-weight: rem-calc(19); 
                    @include breakpoint(medium only) {
                        margin-bottom: rem-calc(40);
                    }
                    @include breakpoint(large) {
                        width: 37%;
                    }
                  
                }
            }
            &__list{
                position: relative;
                &__item{
                    list-style: none;
                    color: $title;
                    margin-bottom: rem-calc(10);
                    font-size: rem-calc(12.5);
                    font-weight: rem-calc(16);
                    margin-left: rem-calc(10);
                   @include breakpoint(medium){
                        font-size: rem-calc(14);
                        font-weight: rem-calc(19);
                        
                   }
                    &::before{
                         height: 100%;
                         width: 100%;
                        content: "";
                        background-image: url(../img/icons/dot.png);
                        background-repeat: no-repeat;
                        margin-top: rem-calc(5);
                        left: rem-calc(-7.5);
                        position: absolute;
                    }
                    

                }
            }
            .btn{
                @include btn; 
                @include breakpoint(small only){
                    padding-left: rem-calc(10);
                    height: rem-calc(40);
                    position: relative;
                    z-index: 9;
                }             
                @include breakpoint(medium){
                    padding: rem-calc(20 50);
                    top: rem-calc(30);
                    position: relative;
                    font-weight: 700;
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                }

            }
        }
    }
}