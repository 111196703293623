.widget-products{
    padding-top: rem-calc(35);
    padding-bottom: rem-calc(62);
    @extend .widget;
    &--success{
        padding-top: rem-calc(60);       
    }
    &__title{
        @include title;
        text-align: center;
        margin-bottom: rem-calc(15);
        &--success{
            font-size: rem-calc(24);
            line-height: rem-calc(33);
        }
    }
    &__text{
        @include content;
        text-align: center;
        margin: auto;
        margin-top: 0;
        margin-bottom: rem-calc(40);
        color: $text-grey;
        font-weight: 600;       
        text-align: center;
        @include breakpoint(medium){
            width: 75%;
        }
        
    }
    &__confirm{  
        margin: rem-calc(40 0);       
        @extend .account__form__item--confirm;
     
        label{
            color: $black;           
        }
    }
    &__buttons{
        text-align: center;
        button,
        a{
            @include btn;            
        }
        .disable-btn {
            background-color: #F4F5F7;
            cursor: not-allowed;
            pointer-events: none;
        }
        &--success{
            a{
               @include breakpoint(small only){
                    font-size: rem-calc(14);
               }
            }
        }
    }
}

.products{
  display: flex;
  flex-direction: column;
  &__item{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-bottom: rem-calc(20);

        &__img {
            @extend .widget__connect__img;

        }

        &__user {
            @extend .widget__connect__user;
        }
  }
  &__item:last-child{
      position: relative;
      left: rem-calc(-32);
  }
}

