.campaign{
    &__input{
        margin-bottom: rem-calc(38);
        &__label{
            color: #323A46;
            font-size: rem-calc(14);
            line-height: rem-calc(19); 
            font-weight: 700;           
        }
        &__name{
            border: 1px solid $text-grey;
            border-radius: 4px;
            margin: rem-calc(12 0);

        }
      
        &__content{
            color: $text-grey;
            font-size: rem-calc(12);
            line-height: rem-calc(16);           

        }
    }
    &__invest{ 
        margin-bottom: rem-calc(36);  
        h2{
            font-size: rem-calc(14);
            line-height: rem-cal(19);
            font-weight: 700;
            color: $title;
            margin-bottom: rem-calc(13);
        }  
        &__value{
            display: flex;           
            margin-bottom: rem-calc(24);
            flex-direction: column;
            justify-content: center;
            @include breakpoint(medium){
                 flex-direction: row;
                 justify-content: space-between;
                 align-items: center;
            }
                .range-container{
                     p {
                         flex: 1;
                         display: flex;
                         height: 100%;
                         align-items: center;
                         color: #1ABC9C;
                         font-weight: 700;
                         font-size: rem-calc(14);
                         line-height: rem-calc(19);
                         span:last-child {
                             margin-left: rem-calc(7);
                             cursor: pointer;
                         }
                         #num-value {
                             border: 1px solid #6C757D;
                             border-radius: 4px;
                             padding: rem-calc(5);
                             font-size: rem-calc(18);
                             color: currentColor;
                             font-weight: 700;
                             line-height: rem-calc(25);
                             text-align: center;
                             width: rem-calc(80);
                             text-align: center;
                             margin: rem-calc(0 5);
                                                        
                         }
                     }
                     span:last-child {
                         margin-left: rem-calc(4);
                         cursor: pointer;
                  }
                   
             }
             p{
                 font-size: 12px;
                 line-height: 16px;
                 font-weight: 400;
                 color: #1ABC9C;
             }         
                          
        }
       &__content{
            text-align: center;
            color: $text-grey;
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            @include breakpoint(small only){
                margin-bottom: rem-calc(-30);
            }              
            span{
                margin-left: rem-calc(4);
                cursor: pointer;
            }
        }
    }
    &__period{  
        &__shape {
            position: absolute;
            display: none;
            @include breakpoint(large) {
                width: rem-calc(540);
                height: rem-calc(248);
                display: block;                           
            }
            @include breakpoint(large) {
                width: rem-calc(530);
                height: rem-calc(248);
                display: block;                           
            }
        }
        .block{
            margin-bottom: rem-calc(40);
             position: relative;
             padding: rem-calc(10);
             padding-top: rem-calc(20);
           @include breakpoint(medium){
                 background-color: $white;
                 box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.25);                
                 left: rem-calc(-12);
                 top: rem-calc(-25);
                 
           }
          } 
           &__thumb {
                position: relative;
                z-index: 3;  
                @include breakpoint(small only){
                   top: 0;
                   left: rem-calc(20);
                   transform: translateX(-50%, -50%);
                }               
            }             
            &__info {  
                position: relative;
                @include breakpoint(small only){
                    top: rem-calc(32);
                }
                @include breakpoint(medium){
                    left: rem-calc(45);                    
                }                                 
                div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: rem-calc(4);                 
                    h2 {
                        color: $primary;
                        font-size: rem-calc(15);
                        line-height: rem-calc(20.5);
                        font-weight: 700;
                        margin-left: rem-calc(12);
                        @include breakpoint(medium) {
                            width: 50%;

                        }
                    }
                }

                p {
                    @extend .campaign__invest__content;
                    text-align: left;
                    margin-bottom: rem-calc(16);

                    span {
                        color: #1ABC9C;
                        font-weight: 700;
                        margin-left: 0;
                    }

                    @include breakpoint(medium) {
                        width: 60%;

                    }
                }
            }

            &__content {
                @extend .campaign__invest__content;
                margin-bottom: rem-calc(32);
            }
        }

    }

